// @flow

import * as React from "react";

const COLORS = {
  dark: {
    backgroundColor: "#562d24",
    foregroundColor: "#ffffff",
  },
  light: {
    backgroundColor: "#ffffff",
    foregroundColor: "#562d24",
  },
};

type Props = {dark: true} | {light: true} | {};

export default function LogoMini(props: Props) {
  const colors = props.light ? COLORS.light : COLORS.dark;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <path
        fill={colors.backgroundColor}
        d="M15,2.13162821e-14 C6.71555556,2.13162821e-14 0,6.71555556 0,15 C0,23.2844444 6.71555556,30 15,30 C23.2844444,30 30,23.2844444 30,15 C30,6.71555556 23.2844444,2.13162821e-14 15,2.13162821e-14 L15,2.13162821e-14 Z"
      />
      <path
        fill={colors.foregroundColor}
        d="M25.1233333 20.0488889C24.4411111 20.0488889 23.8888889 19.4966667 23.8888889 18.8144444 23.8888889 18.1333333 24.4411111 17.58 25.1233333 17.58 25.8055556 17.58 26.3577778 18.1333333 26.3577778 18.8144444 26.3577778 19.4966667 25.8055556 20.0488889 25.1233333 20.0488889L25.1233333 20.0488889zM23.3777778 20.0477778L18.8177778 20.0477778 18.8177778 19.9633333C19.7266667 19.9633333 19.9788889 19.5933333 19.9788889 19.1722222L19.9788889 14.0244444C19.9788889 12.7122222 19.2888889 12.2411111 18.3633333 12.2411111 17.7244444 12.2411111 17.2022222 12.3755556 16.6811111 12.8466667 17.22 12.0888889 18.33 11.4666667 19.5577778 11.4666667 21.0722222 11.4666667 22.2 12.1566667 22.2 14.0077778L22.2 19.1722222C22.2 19.5933333 22.4522222 19.9633333 23.3777778 19.9633333L23.3777778 20.0477778zM17.3544444 20.0477778L12.7777778 20.0477778 12.7777778 19.9633333C13.7033333 19.9633333 13.9555556 19.5933333 13.9555556 19.1722222L13.9555556 9.21222222C13.9555556 8.65777778 13.67 8.40555556 12.7944444 8.40555556L12.7944444 8.32111111C14.1577778 8.32111111 16.1766667 7.68111111 16.1766667 7.68111111L16.1766667 19.1722222C16.1766667 19.5933333 16.4288889 19.9633333 17.3544444 19.9633333L17.3544444 20.0477778z"
      />
      <path
        fill={colors.foregroundColor}
        d="M11.4444444 15C11.1077778 15 10.8044444 14.9155556 10.6366667 14.6633333 11.0233333 14.5122222 11.3266667 14.1255556 11.3266667 13.5533333 11.3266667 12.9811111 10.8888889 12.5266667 10.2322222 12.5266667 9.67777778 12.5266667 9.22333333 12.7455556 8.95444444 13.3011111 9.27333333 12.2077778 10.1144444 11.4666667 11.1744444 11.4666667 12.3022222 11.4666667 12.9755556 12.2577778 12.9755556 13.25 12.9755556 14.2933333 12.2344444 15 11.4444444 15L11.4444444 15zM9.57666667 20.0477778L5 20.0477778 5 19.9633333C5.92555556 19.9633333 6.17777778 19.5933333 6.17777778 19.1722222L6.17777778 12.9977778C6.17777778 12.4433333 5.89222222 12.19 5.01666667 12.19L5.01666667 12.1066667C6.38 12.1066667 8.39888889 11.4666667 8.39888889 11.4666667L8.39888889 19.1722222C8.39888889 19.5933333 8.65111111 19.9633333 9.57666667 19.9633333L9.57666667 20.0477778z"
      />
    </svg>
  );
}
