// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import Background from "./background.svg";
import TaglineImage from "./tagline.svg";

const Wrapper = styled.div`
  margin-bottom: calc(var(--lineHeight) * 1);

  @media only screen and (min-width: 601px) {
    padding: calc(var(--lineHeight) * 1);
    padding-left: 0;
    display: flex;
    margin-left: calc(var(--lineHeight) * 2);
  }
`;

const Header = styled.div`
  position: relative;
  display: block;
  margin: 0;
  color: #283656;

  &:before {
    content: "";
    display: block;
    z-index: -1;
    width: 130%;
    height: 170%;
    left: -8%;
    top: -25%;
    position: absolute;
    background: url(${Background});
    background-repeat: no-repeat;
    background-size: contain;

    /* for window width <= 600 */
    @media only screen and (max-width: 600px) {
      width: 150%;
      height: 190%;
      left: -24%;
      top: -20%;
    }
  }

  /* for window width <= 600 */
  @media only screen and (max-width: 600px) {
    & > h1 {
      text-align: center;
      font-weight: 700;
      letter-spacing: -0.03em;
      font-size: var(--h2-size);
      line-height: calc(var(--lineHeight) * 2);
      margin-left: calc(var(--h2-size) / -26);
    }
  }
`;

const Tagline = styled.div`
  background: url(${TaglineImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: calc(var(--lineHeight) * 2);
  margin-left: 48px;
  margin-top: -6px;
  opacity: 0.9;
  text-indent: -9999px;

  /* for window width <= 600 */
  @media only screen and (max-width: 600px) {
    margin-left: 0;
    width: 95%;
    margin: 0 auto;
    margin-top: -6px;
  }
`;

type Props = {};

export default class CallToAction extends React.PureComponent<Props> {
  render() {
    return (
      <Wrapper>
        <Header>
          <h1 className="m0 p0">Rhythm Health</h1>
          <Tagline>a holistic approach...</Tagline>
        </Header>
      </Wrapper>
    );
  }
}
