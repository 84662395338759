// @flow

import * as React from "react";
import {styled} from "@linaria/react";

const Wrapper = styled.section`
  color: #fff;
  position: relative;
  overflow: hidden;
`;

const Header = styled.header`
  background: #f4f5f3;
  color: #252425;
  box-sizing: border-box;
  padding: calc(var(--lineHeight) * 0.4) var(--lineHeight) 0 var(--lineHeight);
  padding-bottom: 0;

  & > h6 {
    opacity: 0.5;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.65em;
    line-height: calc(var(--lineHeight) * 0.7);
    padding-top: calc(var(--lineHeight) * 0.3);
  }

  & > h4 {
    padding: 0;
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    margin-top: calc(var(--lineHeight) * 2);
  }
`;

const Content = styled.div`
  background: #f4f5f3;
  text-align: center;
`;

const BookButton = styled.a`
  box-sizing: border-box;
  display: block;
  background: #91c4cd;
  color: #fff;
  text-align: center;
  width: 100%;
  line-height: calc(var(--lineHeight) * 2);
  padding: 0 var(--lineHeight);
  text-decoration: none;
  font-size: var(--h3-size);
  font-weight: 600;
  border-radius: 4px;

  &:hover {
    background: #6ba3ad;
  }
`;

type Props = {};

export default class BookingPanel extends React.PureComponent<Props> {
  render() {
    return (
      <Wrapper>
        <Header className="h-3">
          <h6>Want to come see us?</h6>
          <h4>Make a Booking!</h4>
        </Header>
        <Content className="p1 pt0">
          <BookButton href="https://rhythm-health-pty-ltd.au2.cliniko.com/bookings">Book Online</BookButton>
        </Content>
      </Wrapper>
    );
  }
}
