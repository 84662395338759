// @flow

import * as React from "react";
import {useStaticQuery, graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "src/components/seo";
import Masthead from "src/components/Masthead";

type Props = {};

export default function AboutPage(props: Props) {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      image: file(relativePath: {eq: "about-8.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 902)
        }
      }

      image2: file(relativePath: {eq: "about-6.jpg"}) {
        childImageSharp {
          gatsbyImageData(height: 500)
        }
      }

      image3: file(relativePath: {eq: "about-9.jpg"}) {
        childImageSharp {
          gatsbyImageData(height: 500)
        }
      }
    }
  `);

  return (
    <>
      <SEO title="About" />

      <Masthead />

      <grid columns="12" columns-s="6">
        <c start="1" end="12" span-s="row">
          <h1>About Us</h1>
        </c>
        <c start="1" end="5" span-s="row">
          <h3>Our Beliefs</h3>
          <p>
            Rhythm Health is a place where patients are supported with compassion, and guided with best practice
            clinical care. We think it is vital we allow time to hear each patient’s story, and we value our focus on
            expert hands-on care. We love to fine tune the way the body works, and feels — like a kind of body mechanic,
            considering and treating each patient as a whole.
          </p>

          <p>
            We think it is so important to allow time to look after your physical health, with the care and appreciation
            you deserve—honouring what is really, our most vital asset.
          </p>
        </c>
        <c start="7" end="12" span-s="row">
          <grid columns="6">
            <c start="1" end="3" span-s="row">
              <GatsbyImage image={data.image2.childImageSharp.gatsbyImageData} />
            </c>

            <c start="4" end="6" span-s="row">
              <GatsbyImage image={data.image3.childImageSharp.gatsbyImageData} />
            </c>
          </grid>

          <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} />
        </c>
      </grid>
    </>
  );
}
