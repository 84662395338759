// @flow

import * as React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";
import defaultImage from '../images/default-1400x800.jpg';
import ogImage from '../images/og-1200x630.jpg';


function SEO({description, lang, meta, keywords, title, location}) {
  const {site: {siteMetadata}} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            keywords
            siteUrl
            title
          }
        }
      }
    `
  );

  const metaDescription = description || siteMetadata.description;
  const metaTitle = title || siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      defaultTitle={siteMetadata.title}
      titleTemplate={`%s — ${siteMetadata.title}`}
      meta={[
        {
          itemprop: 'name',
          content: metaTitle,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'image',
          content: `${siteMetadata.siteUrl}${defaultImage}`,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },

        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: `${siteMetadata.siteUrl}${ogImage}`,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:locale',
          content: 'en_GB',
        },
        {
          property: 'og:url',
          content: `${siteMetadata.siteUrl}${location?.pathname ?? ''}`,
        },
        {
          name: 'keywords',
          content: siteMetadata.keywords,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
