// @flow

import * as React from "react";
import SEO from "src/components/seo";
import Masthead from "src/components/Masthead";

export default () => (
  <>
    <SEO title="Your Appointment" />

    <Masthead />

    <grid columns="12" columns-s="6">
      <c start="4" end="9" span-s="row">
        <h1>Your Appointment</h1>

        <h2>Frequently Asked Questions</h2>

        <h3>What do I need to bring?</h3>
        <p>
          Bring along any X-rays, scans or test results that you may have. If possible, wear something comfy to your
          appointment so you can move around freely. Depending on the area of your body requiring examination, your
          osteopath may ask you to undress to your underwear. It is important that you feel comfortable, so you may want
          to bring a pair of stretchy gym shorts to change into but we always have some spares available too. Your
          Osteopath will ensure you feel completely comfortable during your treatment. Plan to attend along with your
          child if your child is the patient.
        </p>
        <p>
          Your private health care card if you have EXTRAS cover, so you can claim your private health insurance. We
          have HICAPS so you can claim on the spot just bring your card along for your rebate, then pay the gap.
        </p>

        <h3>Your First Consultation</h3>
        <h6>Before the consultation</h6>
        <p>
          It is advisable to arrive a few minutes early for your first appointment, as you will need to fill out some
          paperwork.
        </p>
        <p>
          You will be asked to sign a general consent form, but you will have the opportunity to consider and consent
          (or not) to specific treatment in the consultation.
        </p>

        <h6>During the consultation</h6>
        <p>
          Your osteopath will ask questions about your problem and symptoms. They may also ask about your health
          history, any medications you are taking, as well as factors that may not appear to be directly related to your
          problem. If your health condition changes between osteopathic consultations you should tell your osteopath at
          your next consultation.
        </p>
        <p>
          Your osteopath will conduct a full osteopathic assessment involving clinical tests. This may involve
          diagnostic, orthopaedic or neurological tests, postural assessments, movement and functional assessments. Some
          assessments involve the osteopath observing you while you undertake an activity or perform a sequence of
          movements. Assessments may also include passive and active movements - where the osteopath manually lifts your
          arms or legs or applies resistance while you perform a stretch or movement.
        </p>
        <p>
          Osteopathy takes a holistic approach to treatment, so your practitioner may look at other parts of your body,
          as well as the area that is troubling you. For example, if you have a sore knee, your osteopath may also look
          at your ankle, pelvis and back.
        </p>
        <p>
          Your osteopath may also provide education and advice to help you manage your condition between appointments.
          This may include giving you exercises to do at home or work.
        </p>

        <h3>Do I need a referral from my GP?</h3>
        <p>
          Many patients are referred to osteopaths by their doctors, other health practitioners or personal trainers.
          However, as osteopaths are primary care practitioners, you can make an appointment directly without a
          referral.
        </p>

        <h3>Is osteopathic treatment painful?</h3>
        <p>
          Osteopathy combines hands on manual therapy with exercise programming, dry needling, other interventions like
          equipment prescription and movement advice when clinically indicated. Hands-on treatment may include massage,
          stretching, repetitive movements, mobilisation and/or manipulation. If your injuries require hands-on
          treatment of painful and tender areas, your osteopath will exercise care to make you as comfortable as
          possible.
        </p>
        <p>
          Some people experience mild soreness for a day or two after treatment, similar to that felt after mild
          exercise. If this soreness persists or increases, call your osteopath to discuss your concerns.
        </p>

        <h2>Rates</h2>
        <dl>
          <dt>
            <strong>Initial Consultation</strong>
          </dt>
          <dd>$150 — 60 minutes</dd>

          <dt>
            <strong>Standard Consultation</strong>
          </dt>
          <dd>$100 — 30 minutes</dd>
          <dd>$125 — 45 minutes</dd>
        </dl>
        <ul className="compact">
          <li>HICAPS is available on site. Private health rebates available for extras cover.</li>
        </ul>
      </c>
    </grid>
  </>
);
