// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import background from "./Rule.svg";

const Rule = styled.hr`
  display: block;
  border: none;
  opacity: 0.1;
  background: none;
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    max-width: 774px;
    margin: 0 auto;

    background-image: ${props => `url("${background}")`};
    background-position: center;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const RuleLeft = styled(Rule)`
  background-position: left center;
`;

type Props = {
  left?: boolean,
};

export default function(props: Props) {
  return props.left ? <RuleLeft className="h-2" /> : <Rule className="h-2" />;
}
