// @flow

type Hours = Array<Availability>;
type Availability = {|
  label: string,
  hours: string | null,
|};

const hours: Hours = [
  {
    label: "Monday",
    hours: "9.00am – 6.30pm",
  },
  {
    label: "Tuesday",
    hours: "10.00am – 6.30pm",
  },
  {
    label: "Wednesday",
    hours: "8.30am – 6.30pm",
  },
  {
    label: "Thursday",
    hours: "8.00am – 7.00pm",
  },
  {
    label: "Friday",
    hours: "8.30am – 2.00pm",
  },
  {
    label: "Saturday",
    hours: "8.00am – 2.00pm",
  },
  {
    label: "Sunday",
    hours: null,
  },
];

export default hours;
