// @flow

import * as React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {styled} from "@linaria/react";
import SEO from "src/components/seo";
import Masthead from "src/components/Masthead";
import { GatsbyImage } from "gatsby-plugin-image"
import LogoMini from "src/components/LogoMini";
import Rule from "src/components/Rule";
import GoogleMapReact from "google-map-react";
import hours from "src/data/hours";

function MapPin() {
  return (
    <LogoPosition>
      <LogoMini dark />
    </LogoPosition>
  );
}

const mapCoords = {
  rho: {
    lat: -37.766470,
    lng: 144.9993125,
  },
};

const MapContainer = styled.div`
  height: 100%;
  margin-bottom: 40px;

  /* for window width <= 600 */
  @media only screen and (max-width: 600px) {
    height: calc(var(--baseline) * 36);
  }
`;

const LogoPosition = styled.svg`
  height: 30px;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
`;

export default function ContactPage() {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      image: file(relativePath: {eq: "contact.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1308)
        }
      }
    }
  `);

  return (
      <>
      <SEO title="Contact" />

      <Masthead />

      <grid columns="12" columns-s="6">
        <c start="1" end="12" span-s="row">
          <h1>Contact Us</h1>
        </c>

        <c start="1" end="5" span-s="row">
          <p>
            Find us at 452 High Street, Northcote, just north of Separation St/Arthurton&nbsp;Rd.
          </p>
          <p>
            There is time-limited car parking on High Street, Langwells Parade, McFarlene St and
            Beavers Road. The Northcote Plaza Shopping Centre carpark also typically has lots of
            parking&nbsp;available.
          </p>
          <p>
            We are also located on the 86 tram route, and 400m walking distance from Croxton train
            station on the Mernda&nbsp;line.
          </p>
          <p>
            Unfortunately our clinic does not have wheelchair&nbsp;access.
          </p>
          <p>
            We acknowledge the traditional owners of the lands on which we live and practice,
            the Wurundjeri people. We acknowledge that sovereignty was never ceded and pay our
            respects to elders past, present and&nbsp;future.
          </p>
        </c>

        <c start="7" end="12" span-s="row">
          <grid columns="6" columns-s="1">
            <c span="3" span-s="row">
              <h4 class="single-line">Find Us</h4>
              <p>
                452 High St
                <br />
                Northcote
                <br />
                Victoria 3070
              </p>

              <h4 class="single-line">Say Hello</h4>
              <p>
                Phone 03 7036 6637
                <br />
                Fax 03 7036 6638
                <br />
                <a href="mailto:hello@rhythm-health.com.au">hello@rhythm-health.com.au</a>
              </p>
            </c>

            <c span="3" span-s="row">
              <h4 class="single-line">Opening Hours</h4>
              <grid class="compact" columns="3" columns-s="3">
                {hours.filter((day) => day.hours !== null).map((day) => (
                  <>
                    <c span="1">
                      {day.label}
                    </c>
                    <c span="2">
                      {day.hours}
                    </c>
                  </>
                ))}
              </grid>
            </c>
          </grid>
        </c>

        <c start="1" end="6" span-s="row">
          <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} />
        </c>

        <c start="7" end="12" span-s="row">
          <MapContainer>
            <GoogleMapReact
              bootstrapURLKeys={{key: "AIzaSyCxC3rVA-MDIcf4Z4nhdouw_QsEld-CNKI"}}
              defaultCenter={mapCoords.rho}
              defaultZoom={18}
              options={{mapTypeControl: false}}
            >
              <MapPin {...mapCoords.rho} />
            </GoogleMapReact>
          </MapContainer>
        </c>
      </grid>
    </>
  );
};
