// @flow

import * as React from "react";
import {useStaticQuery, graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "src/components/seo";
import Masthead from "src/components/Masthead";
import Rule from "src/components/Rule";

export default function OurTeam() {
  const data = useStaticQuery(graphql`
    query OurTeamQuery {
      tegan: file(relativePath: {eq: "headshots/tegan.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 764, height: 900)
        }
      }
      nat: file(relativePath: {eq: "headshots/nat.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 764, height: 900)
        }
      }
      chris: file(relativePath: {eq: "headshots/chris.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 764, height: 900)
        }
      }
      daniela: file(relativePath: {eq: "headshots/daniela.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 764, height: 900)
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Our Team" />

      <Masthead />

      <grid columns="12" columns-s="6">
        <c start="5" span-s="row">
          <h1>Our Team</h1>
        </c>
      </grid>

      <grid columns="12" columns-s="6">
        <c start="5" span-s="row">
          <h2 className="m0">Dr. Chris Tucker</h2>
          <h4>B.App.Sc (Comp. Med) M.Osteopathy</h4>
        </c>
        <c start="3" end="4" span-s="row">
          <GatsbyImage image={data.chris.childImageSharp.gatsbyImageData} />
        </c>
        <c start="5" end="10" span-s="row">
          <p>
            My name is Chris Tucker and I am thrilled to be a part of the Rhythm Health team. I am
            a highly experienced Osteopath, with over 15 years experience in the industry, having worked
            at several well respected clinics around Melbourne. I adopt a live once, live well attitude,
            and encourage people to live the life they want to.
          </p>
          <p>
            Human Anatomy and Physiology has always been a passion of mine, it’s who we are and it’s how
            we function. I lead a very active lifestyle, thus I understand the importance of health
            maintenance and the frustrations that can arise during times of injury. Holding a double
            degree in Clinical Science/Osteopathic Science combined with over 15 years working as an
            Osteopath, I have dealt with a multitude of conditions. I enjoy a challenge and my point of
            focus is always helping someone achieve optimal health.
          </p>
          <p>
            I am a confident, safe, and thorough practitioner who feels very proud to work with what
            is someones greatest asset, their health. I absolutely love being an Osteopath and take
            pride in making my clients feel cared for, knowing that I have the experience and dedication
            necessary to get the best out of them. I treat every appointment with the respect it deserves,
            but I also enjoy working with people and building a rapport with those who come to see me.
          </p>
          <p>
            I am a fun loving person who is always up for an adventure! If it involves the outdoors, I’m
            into it! I love to run, swim, hike, camp, chill at the beach, and most importantly have fun
            with my friends. All these things involve good health, which is why I’m such a strong advocate
            of healthy living.
          </p>
          <p>
            If you need some help getting your health back on track or want to get proactive about getting
            the most out of yourself, come and say hello and we can discuss how I may be able to help you.
          </p>
          <Rule />
        </c>
      </grid>

      <grid columns="12" columns-s="6">
        <c start="5" span-s="row">
          <h2 className="m0">Dr. Daniela De Fazio</h2>
          <h4>App.Sc.(Clin,Sc.); B.Osteo. Sc, Grad Cert in Neo. & Infnt Paed Man. Thrpy</h4>
        </c>
        <c start="3" end="4" span-s="row">
          <GatsbyImage image={data.daniela.childImageSharp.gatsbyImageData} />
        </c>
        <c start="5" end="10" span-s="row">
          <p>
            Daniela graduated from the 5 year full-time double degree course at RMIT University in 2002.
          </p>
          <p>
            During her university years Daniela developed an interest in children’s health and osteopathic
            treatment during pregnancy. She has since undertaken further studies in the fields of cranial
            osteopathy, paediatrics, obstetrics and gynaecology. She maintains an interest in women’s
            health, particularly treating pregnant women.
          </p>
          <p>
            Daniela grew up in the northern suburbs and went to high school at the Academy of Mary
            Immaculate in Fitzroy. Daniela speaks fluent Italian, coming from an Italian background
            and having studied it in high school.
          </p>
          <p>
            Through her heightened interest in children’s health, Daniela has focused her attention
            on learning as much about treating children, as an Osteopath as possible. Common conditions
            that affect infants and children include asthma, recurrent ear infections, scoliosis, or even
            “growing pains”. Often newborns are brought in for an assessment after a traumatic or difficult
            birth for a check-up. Osteopathic treatment to the young can be very rewarding as problems may
            be prevented from developing into chronic conditions in later years The holistic approach
            allows the body’s inherent self-healing mechanisms to assist the natural healing process. In
            2011, Daniela completed a Graduate Certificate in Neonatal and Infant Paediatric Manual Therapy
            at Victoria University in Melbourne, furthering her knowledge in this area. Daniela has treated
            many children and enjoys the interaction with children as much as she enjoys seeing positive
            results. Daniela’s role as a mother brings knowledge through experience and she continues to
            contribute to promoting Osteopathy and its benefits for pregnant women and children of all ages.
          </p>
          <p>
            Osteopathic techniques during pregnancy are carefully selected. Common complaints associated
            with pregnancy include lower back pain, leg pain, and neck or shoulder pain. As a pregnancy
            progresses the extra weight can cause a shift in the body’s centre of gravity causing tight
            muscles leading to aches and fatigue. Daniela takes great care in assuring that the mother
            is comfortable and relaxed during the treatment so that she can give her patient the best
            care possible and achieves optimum results.
          </p>
          <p>
            Daniela has held an assistant teaching position at the RMIT Bundoora campus, teaching the
            Osteopathic students. She has also served three years as a member on the Victorian State Council
            branch of the Australian Osteopathic Association. This role included two years as Continuing
            Professional Development Coordinator, which involved organising information seminars for Osteopaths
            to further their education in various fields. For several years, her role on State Council also
            led her to organise a stand at the annual Melbourne Pregnancy, Babies and Children Expo where
            Osteopathy is promoted to the public.
          </p>
          <Rule />
        </c>
      </grid>

      <grid columns="12" columns-s="6">
        <c start="5" span-s="row">
          <h2 className="m0">Dr. Natalie Maynard</h2>
          <h4>B.App.Sc (Health Science), B.App.Sc (Osteopathy)</h4>
        </c>
        <c start="3" end="4" span-s="row">
          <GatsbyImage image={data.nat.childImageSharp.gatsbyImageData} />
        </c>
        <c start="5" end="10" span-s="row">
          <p>
            Natalie found her passion for Osteopathy through her love of horse riding from a very young age of 6. She
            understood early on through injuries, the importance of her body functioning correctly to achieve optimum
            results.
          </p>
          <p>
            Natalie commenced her academia life at La Trobe University completing a Bachelor of Health Science,
            continuing on to graduate from RMIT University with a Bachelor of Health Science/Applied Science (Osteopathy)
            with Distinction.
          </p>
          <p>
            Since graduating RMIT, she has completed training in dry needling as well as certification through APPI
            Clinical Pilates, enhancing her knowledge on exercise rehabilitation, creating programs to compliment her
            treatments to promote a successful outcome.
          </p>
          <p>
            Natalie has also worked within the dental field, working with TMJ and facial pain,
          </p>
          <p>
            During a consultation and treatment, she will take a thorough history and examination to identify any
            aggravating lifestyle factors, to ensure patient education and an individualized treatment/management
            plan.
          </p>
          <p>
            In her spare time, Natalie is kept busy with her horses, taking time out to enjoy yoga and Pilates,
            exploring new places, and spending time with family and friends.
          </p>
          <Rule />
        </c>
      </grid>

      <grid columns="12" columns-s="6">
        <c start="5" span-s="row">
          <h2 className="m0">Dr. Tegan Jennings</h2>
          <h4>B.App.Sc (Comp. Med) M.Osteopathy</h4>
        </c>
        <c start="3" end="4" span-s="row">
          <GatsbyImage image={data.tegan.childImageSharp.gatsbyImageData} />
        </c>
        <c start="5" end="10" span-s="row">
          <p>
            Tegan fell in love with osteopathy as a teenager — drawn to the proactive, hands on approach, knowledgeable
            practitioners, and emphasis on considering the patient as a whole. It was at this point, Tegan firmly set
            her sights on becoming an osteopath herself, and has never looked back.
          </p>
          <p>
            Years later, Tegan is a registered osteopath who completed her qualifications at RMIT University in
            Melbourne, and has worked extensively in both rural Victoria and Melbourne, gaining experience treating
            patients from all walks of life and with a diverse range of conditions.
          </p>
          <p>
            Tegan is a passionate about osteopathy and enjoys coming up with a treatment plan and approach that works
            best for each patient. She is most fulfilled finding ways to enable and empower women to manage pain,
            prevent injury and as a result, live better lives.
          </p>
          <p>
            With this patient-centered approach, Tegan often works alongside other health professionals ensuring her
            patients are able to take an active role in their health and wellbeing with the right support.
          </p>
          <p>
            Tegan has a special interest in treating women of all ages and the whole body. She has dedicated her
            practice to women and has completed many additional related courses including pain management during
            pregnancy and beyond, pain science, and osteopathic technique for female pelvic pain and urogenital
            conditions, as well as dry needling.
          </p>
        </c>
      </grid>
    </>
  );
}
