// @flow

import {styled} from "@linaria/react";

const WidthContainer = styled.div`
  overflow-x: hidden;
  max-width: 1840px;
  /*width: 100%;*/
  margin: 0 auto;
  padding: 0 calc(var(--lineHeight) * 2);

  @media only screen and (max-width: 600px) {
    padding: var(--lineHeight);
    padding-bottom: 0;
  }
`;

export default WidthContainer;
