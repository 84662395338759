// @flow

import * as React from "react";
import {css} from "@linaria/core";
import {styled} from "@linaria/react";
import {useStaticQuery, graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import CallToAction from "src/components/CallToAction";

const BackgroundWrapper = styled.div`
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const HeroBackground = styled.div`
  max-width: 2000px;
  margin: 0 auto;
  overflow: visible;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${props => `url(${props.backgroundImage})`};
    background-repeat: none;
    background-size: contain;
  }
`;

const HeroContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* for window width <= 600 */
  @media only screen and (max-width: 600px) {
    margin-bottom: calc(var(--lineHeight) * 1);
  }
`;

const CallToActionPosition = styled.div`
  padding-left: 0;

  /* for window width > 601 */
  @media only screen and (min-width: 601px) {
    margin-bottom: calc(var(--lineHeight) * 1);
  }
`;

type Props = {
  children?: React.Node,
};

export default function Hero(props: Props) {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      hero: file(relativePath: {eq: "hero.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 2000, height: 800, quality: 90)
        }
      }

      tile: file(relativePath: {eq: "tile.jpg"}) {
        publicURL
      }

      squiggles: file(relativePath: {eq: "hero-squiggles.svg"}) {
        publicURL
      }
    }
  `);

  return (
    <>
      <BackgroundWrapper backgroundImage={data.tile.publicURL}>
        <HeroBackground backgroundImage={data.squiggles.publicURL} className="h-22">
          <GatsbyImage
            image={data.hero.childImageSharp.gatsbyImageData}
            alt="Image of a practitioner treating a woman"
            style={{position: "absolute", bottom: 0, width: "100%", height: "100%"}}
          />
        </HeroBackground>
      </BackgroundWrapper>
      <HeroContainer className="h-20">
        {props.children}
      </HeroContainer>
    </>
  );
}
