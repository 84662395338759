// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import WidthContainer from "src/components/WidthContainer";
import Footer from "src/components/Footer";

const Main = styled.main`
  --color-primary: #ede9e1;
  --color-accent: #bb6d4a;
  --color-main: #562d24;

  --color-dim: #9a8c98;

  color: var(--color-main);

  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 601px) {
    padding-top: calc(var(--lineHeight) * 2);
  }
`;

type Props = {
  children?: React.Node,
};

export default function Theme(props: Props) {
  return (
    <Main>
      <WidthContainer>
        {props.children}
      </WidthContainer>
      <Footer />
    </Main>
  );
}
