// @flow

import * as React from "react";
import {css} from "@linaria/core";
import {Link as GatsbyLink} from "gatsby";

const anchor = css`
  display: block;
  text-decoration: none;
  color: #373c3f;

  @media only screen and (max-width: 600px) {
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: var(--lineHeight);

    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: var(--h3-size);
  }

  @media only screen and (min-width: 601px) {
    padding-top: calc(var(--lineHeight) * 0.15);
    padding-left: calc(var(--lineHeight) * 0.75);
    padding-right: calc(var(--lineHeight) * 0.75);

    &:last-child {
      padding-right: 0;
    }

    &:hover > span {
      border-bottom: 0.1em solid rgba(31, 46, 81, 0.5);
    }

    &.is-active {
      opacity: 1;

      @media only screen and (min-width: 601px) {
        & > span {
          border-bottom: 0.1em solid;
        }
      }
    }
  }
`;

const buttonAnchor = css`
  display: block;
  text-decoration: none;
  background: #91c4cd;

  &, &:visited {
    color: #fff;
  }

  &:hover {
    background: #6ba3ad;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
    padding: var(--lineHeight);
    font-weight: 700;
    letter-spacing: -0.02em;
    font-size: var(--h3-size);
    border-radius: 50px;
    margin: var(--lineHeight);
  }

  @media only screen and (min-width: 601px) {
    padding-top: calc(var(--lineHeight) * 0.15);
    padding-bottom: calc(var(--lineHeight) * 0.15);
    padding-left: calc(var(--lineHeight) * 0.75);
    padding-right: calc(var(--lineHeight) * 0.75);
    border-radius: 20px;
  }
`;

export function ButtonLink({router, children, ...props}) {
  return (
    <a className={buttonAnchor} href={props.href} title={props.title}>
      <span>{props.title}</span>
    </a>
  )
}

export default function Link({router, children, ...props}) {
  return props.to ? (
    <GatsbyLink className={anchor} activeClassName="is-active" title={props.title} to={props.to}>
      <span>{props.title}</span>
    </GatsbyLink>
  ) : (
    <a className={anchor} href={props.href} title={props.title}>
      <span>{props.title}</span>
    </a>
  );
}
