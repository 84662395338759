// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import NavItem from "./NavItem";

const Wrapper = styled.nav`
  margin-bottom: 0;

  @media only screen and (min-width: 601px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a:hover + nav > a {
      opacity: 0.5;
    }
  }
`;

const SubNavItems = styled.nav`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) {
    display: flex;
    flex-direction: row;
    align-self: center;
    position: absolute;
    font-size: 0.8rem;
    font-weight: 600;
    top: var(--lineHeight);
    padding-top: calc(var(--lineHeight) * 0.2);

    & > a {
      opacity: 0.3;
      transition-property: opacity;
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;
      padding-left: 20px;
      padding-right: 20px;
    }

    &:hover a {
      opacity: 0.5;
    }

    & > a:hover {
      opacity: 1;
    }
  }
`;

type SubNavProps = {
  title: string,
  to?: string,
  children: React.ChildrenArray<React.Element<typeof NavItem>>,
};

export default class SubNav extends React.PureComponent<SubNavProps> {
  render() {
    return (
      <Wrapper>
        <NavItem title={this.props.title} to={this.props.to} />
        <SubNavItems>{this.props.children}</SubNavItems>
      </Wrapper>
    );
  }
}
