// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import NavItem, {ButtonLink} from "./NavItem";
import SubNav from "./SubNav";

const Nav = styled.nav`
  /* for window width <= 600 */
  @media only screen and (max-width: 600px) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: flex-start;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  color: #373c3f;
`;

const MicroNav = styled.nav`
  /* for window width <= 600 */
  @media only screen and (min-width: 601px) {
    display: none;
  }

  margin: 0;
  align-self: center;

  & > details summary:hover {
    pointer: cursor;
  }

  & > details summary {
    outline: none;
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }
  }

  & svg {
    position: relative;
    z-index: 101;
  }

  & path {
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease;
  }

  & > details[open] path#bar1 {
    transform: translate3d(20px, -7px, 0) rotate(45deg);
  }

  & > details[open] path#bar2 {
    transform: translate3d(4px, 34px, 0) rotate(-45deg);
  }

  & > details[open] > div {
    padding-top: calc(var(--lineHeight) * 4);
    height: 100vh;
    width: 100vw;
    background: #fff;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    box-sizing: border-box;
  }
`;

type Props = {};
type State = {
  navOpen: boolean,
};

export default class Navigation extends React.PureComponent<Props, State> {
  state = {
    navOpen: false,
  };

  componentDidMount() {
    this.derpyOverflowHack();
  }

  componentDidUpdate() {
    this.derpyOverflowHack();
  }

  render() {
    return (
      <>
        <Nav>{this.renderNavItems()}</Nav>
        <MicroNav>
          <details>
            <summary onClick={this.handleMicroNavClick}>
              <svg width="44px" height="42px" viewBox="0 0 42 42">
                <path
                  id="bar1"
                  fill="#562d24"
                  transform="translate(0 10) rotate(0)"
                  d="M3,16 L39,16 C40.6568542,16 42,17.3431458 42,19 L42,19 C42,20.6568542 40.6568542,22 39,22 L3,22 C1.34314575,22 2.02906125e-16,20.6568542 0,19 L0,19 C-2.02906125e-16,17.3431458 1.34314575,16 3,16 Z"
                />
                <path
                  id="bar2"
                  fill="#562d24"
                  transform="translate(0 10) rotate(0)"
                  d="M3,7.10542736e-15 L39,7.10542736e-15 C40.6568542,6.80106817e-15 42,1.34314575 42,3 L42,3 C42,4.65685425 40.6568542,6 39,6 L3,6 C1.34314575,6 2.02906125e-16,4.65685425 0,3 L0,3 C-2.02906125e-16,1.34314575 1.34314575,7.40978655e-15 3,7.10542736e-15 Z"
                />
              </svg>
            </summary>
            <div>{this.renderNavItems()}</div>
          </details>
        </MicroNav>
      </>
    );
  }

  renderNavItems() {
    return (
      <>
        <NavItem to="/" title="Home" />
        <SubNav to="/about" title="About">
          <NavItem to="/about/our-team" title="Our Team" />
          <NavItem to="/about/your-appointment" title="Your Appointment" />
        </SubNav>
        <NavItem to="/contact" title="Contact" />
        <ButtonLink href="https://rhythm-health-pty-ltd.au2.cliniko.com/bookings" title="Book In" />
      </>
    );
  }

  handleMicroNavClick = () => {
    this.setState({navOpen: !this.state.navOpen}, this.derpyOverflowHack);
  }

  derpyOverflowHack = () => {
    document.body.style.overflowY = this.state.navOpen ? "hidden" : "";
  }
}
