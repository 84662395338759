// @flow

import * as React from "react";
import {styled} from "@linaria/react";

const Anchor = styled.a`
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: right center;
  display: inline-block;
  text-decoration: none;
  color: var(--color-accent);

  &:visited {
    color: var(--color-accent);
  }

  & > svg {
    margin-left: 0.5rem;
  }
`;

type Props = {
  title: string,
  href: string,
  className?: string,
};

export default function ActionButton(props: Props) {
  return (
    <Anchor href={props.href} className={props.className}>
      {props.title}
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21">
        <path
          fill="#D8D8D8"
          fillRule="evenodd"
          d="M518.020786,964.5156 C518.616172,964.972571 519,965.691462 519,966.5 C519,967.308538 518.616172,968.027429 518.020786,968.4844 C517.954662,968.570543 517.882167,968.653367 517.803301,968.732233 L510.732233,975.803301 C509.755922,976.779612 508.17301,976.779612 507.196699,975.803301 C506.220388,974.82699 506.220388,973.244078 507.196699,972.267767 L510.464466,969 L496.5,969 C495.119288,969 494,967.880712 494,966.5 C494,965.119288 495.119288,964 496.5,964 L510.464466,964 L507.196699,960.732233 C506.220388,959.755922 506.220388,958.17301 507.196699,957.196699 C508.17301,956.220388 509.755922,956.220388 510.732233,957.196699 L517.803301,964.267767 C517.882167,964.346633 517.954662,964.429457 518.020786,964.5156 Z"
          transform="translate(-494 -956)"
        />
      </svg>
    </Anchor>
  );
}
