// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import {useStaticQuery, graphql} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "src/components/seo";
import Masthead from "src/components/Masthead";
import Hero from "src/components/Hero";
import Rule from "src/components/Rule";
import ActionButton from "src/components/ActionButton";
import BookingPanel from "src/components/BookingPanel";

import Theme from "src/components/Theme";

const Figure = styled.figure`
  position: relative;

  & > img:last-child {
    position: absolute;
    margin: 0;
    z-index: 1;
    bottom: calc(var(--lineHeight) * 0.5);
    left: calc(var(--lineHeight) * 0.5);
    height: calc(var(--lineHeight) * 3);
    width: calc(var(--lineHeight) * 3);
  }
`;

const FeatureText = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
`;

export default function HomePage(props: Props) {
  const data = useStaticQuery(graphql`
    fragment AboutBackground on File {
      childImageSharp {
        gatsbyImageData(width: 576, height: 330)
      }
    }

    fragment AboutIcon on File {
      publicURL
    }

    query HomePageQuery {
      therapyBackground: file(relativePath: {eq: "home/therapy.jpg"}) {
        ...AboutBackground
      }
      exerciseBackground: file(relativePath: {eq: "home/exercise.jpg"}) {
        ...AboutBackground
      }
      educationBackground: file(relativePath: {eq: "home/education.jpg"}) {
        ...AboutBackground
      }
    }
  `);

  return (
    <>
      <SEO />

      <Hero>
        <Masthead />
      </Hero>

      <grid columns="12" columns-s="6">
        <c start="2" end="7" span-s="row" className="pt2">
          <FeatureText>
            A space to support your health and wellbeing, so you can feel your best and get back to what matters&nbsp;most.
          </FeatureText>
          <FeatureText>
            We take the time to listen carefully, and work to identify the underlying cause of your issues, and work with you to restore balance to the&nbsp;body.
          </FeatureText>
          <FeatureText>
            <ActionButton href="/about" title="About Rhythm Health" className="mr2" />
          </FeatureText>
        </c>
        <c start="9" end="12" span-s="row">
          <div className="pt2">
            <BookingPanel className="h-20" />
          </div>
        </c>
        <Rule />
      </grid>

      <grid columns="12" columns-s="6">
        <c start="2" span-s="row">
          <h2>What We Treat</h2>
        </c>
        <c start="2" end="6" span-s="row">
          <p>
            Rhythm Health provides holistic clinical support for pain, injuries and discomfort as well as preventative
            care.
          </p>
        </c>
        <c start="8" end="11" span-s="row">
          <grid columns="2" className="compact">
            <c span="2">
              <h6>Osteopaths may assist you with the following;</h6>
            </c>
            <c span="1">
              <ul className="compact" className="compact xsmall">
                <li>Back and neck pain</li>
                <li>Muscle and joint pain</li>
                <li>TMJ / jaw pain</li>
                <li>Women’s health including pregnancy care and post-natal support</li>
              </ul>
            </c>
            <c span="1">
              <ul className="compact" className="compact xsmall">
                <li>Headaches</li>
                <li>Pelvic pain</li>
                <li>Sporting injuries</li>
              </ul>
            </c>
          </grid>
        </c>
        <Rule />
      </grid>

      <grid columns="12" columns-s="6">
        <c span="4" span-s="row">
          <article>
            <Figure>
              <GatsbyImage
                alt="Image of a practitioner treating a woman"
                image={data.therapyBackground.childImageSharp.gatsbyImageData}
              />
            </Figure>
            <h3>Manual Therapy</h3>
            <p>We create an individual treatment plan to address each patient’s health needs.</p>
            <p>
              Our hands on approach to treatment may include soft tissue massage, stretching, muscle energy technique,
              dry-needling, articulation of the joints, myofasical release, and gentle&nbsp;manipulation.
            </p>
          </article>
        </c>
        <c span="4" span-s="row">
          <article>
            <Figure>
              <GatsbyImage
                alt="Image of a practitioner examining a patients range of motion"
                image={data.exerciseBackground.childImageSharp.gatsbyImageData}
              />
            </Figure>
            <h3>Exercise & Lifestyle Advice</h3>
            <p>
              We provide ongoing support through your injury rehabilitation to aid your recovery and help prevent
              relapse.
            </p>
            <p>
              We discuss contributing factors such as workplace ergonomics, postural advice and develop simple, specific
              home exercise programs for pain management and&nbsp;prevention.
            </p>
          </article>
        </c>
        <c span="4" span-s="row">
          <article>
            <Figure>
              <GatsbyImage
                alt="Image of a practitioner and patient discussing treatment"
                image={data.educationBackground.childImageSharp.gatsbyImageData}
              />
            </Figure>
            <h3>Education</h3>
            <p>An important part of your treatment plan to is help you better understand your condition.</p>
            <p>
              Your osteopath will help explain your diagnosis using their knowledge of the body systems, functional
              anatomy, biomechanics and pain management. We believe it’s critical that you know what is going on with
              your&nbsp;body.
            </p>
          </article>
        </c>
      </grid>
    </>
  );
}
