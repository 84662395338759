// @flow

import * as React from "react";
import {styled} from "@linaria/react";
import WidthContainer from "src/components/WidthContainer";
import LogoMini from "src/components/LogoMini";
import hours from "src/data/hours";

const FooterWrapper = styled.footer`
  border-top: calc(var(--lineHeight) * 0.25) solid var(--color-main);
  box-sizing: border-box;
  color: var(--color-main);
  position: relative;
  width: 100vw;
  margin-top: calc(var(--lineHeight) * 4);
  padding: calc(var(--lineHeight) * 2);
  padding-top: calc(var(--lineHeight) * 3);
  padding-bottom: calc(var(--lineHeight) * 3);

  @media only screen and (max-width: 600px) {
    padding: 0;
    padding-bottom: var(--lineHeight);
  }
`;

const FooterHeading = styled.h6`
  text-transform: uppercase;
  font-weight: 700;
  /*margin-bottom: var(--lineHeight);*/
`;

const FooterGroup = styled.div`
  margin-bottom: var(--lineHeight);

  &:last-child {
    margin-bottom: 0;
  }
`;

const WelcomeToCountry = styled.p`
  text-align: justify;
`;

type Props = {};

export default function Footer(props: Props) {
  return (
    <FooterWrapper>
      <WidthContainer>
        <grid columns="12" columns-s="6" className="xxsmall">
          <c span="1" span-s="1">
            <LogoMini />
          </c>
          <c start="2" end="3" span-s="row">
            <FooterGroup>
              <FooterHeading>Where</FooterHeading>
              <grid columns="2">
              <c span="1">
                <p>
                  Rhythm Health
                  <br />
                  452 High St
                  <br />
                  Northcote
                </p>
              </c>
              </grid>
            </FooterGroup>
            <FooterGroup>
              <FooterHeading>Contact</FooterHeading>
              <p>
                Phone 03 7036 6637
                <br />
                Fax 03 7036 6638
                <br />
                <a href="mailto:hello@rhythm-health.com.au">hello@rhythm-health.com.au</a>
              </p>
            </FooterGroup>
          </c>
          <c start="5" end="7" span-s="row">
            <FooterGroup>
              <FooterHeading>Book Online</FooterHeading>
              <a href="https://rhythm-health-pty-ltd.au2.cliniko.com/bookings">Book online</a>
            </FooterGroup>
            <FooterGroup>
              <FooterHeading>Hours</FooterHeading>
              <grid columns="2" className="compact">
                <c span="2">Monday – Saturday</c>
                <c span="2">By Appointment</c>
              </grid>
            </FooterGroup>
          </c>
          <c start="10" end="12" span-s="row">
            <WelcomeToCountry>
              We acknowledge the traditional owners of the lands on which we live and practice, the Wurundjeri people. We acknowledge that sovereignty was never ceded and pay our respects to elders past, present and future.
            </WelcomeToCountry>
          </c>
        </grid>
      </WidthContainer>
    </FooterWrapper>
  );
}
